import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';
//import CartProducts from '../components/cart/Products';
import CartProductItem from '../components/cart/ProductItem';
import FulfilmentPopup from '../components/products/FulfilmentTabs';
import AccountBottomNav from '../components/account/BottomNav';
import DeliveryAddress from '../components/checkout/DeliveryAddress';
import DeliverySlot from '../components/checkout/DeliverySlot';
import DeliveryStop from '../components/checkout/DeliveryStop';
import * as Sentry from "@sentry/react";

import { mpoSentry } from '../lib/Sentry';
import * as actions from '../store/actions/index';
import {
    attachDeviceInfoToData,
    getPopupResponsiveSettings,
    isCordova,
    logger,
    nl2br,
    updateObject,
    isAppleSigninLoaded,
    isGoogleSigninLoaded, isAppModePersonal, isDebugMode, parseUrlSegments
} from '../shared/utility';
import { unixDatetimeToDate, unixDatetimeToTime, unixUtcTimeOffsetMins, isProduction } from '../shared/utility';
import { mpoFulfilment } from '../lib/Fulfilment';
import queryString from "query-string";
import {mpoScanner} from "../lib/Scanner";
import {Helmet} from "react-helmet";
import {mpoFirebase} from "../lib/Firebase";
import PickupLocation from "../components/checkout/PickupLocation";
import {mpoOneSignal} from "../lib/OneSignal";
import AppleLogin from "react-apple-login";
import {GoogleLogin} from "@react-oauth/google";
import {decodeToken} from "react-jwt";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const cacheMinsFulfilment = isProduction() ? -15 : -1;
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const hideMerchantCardOnMenu = process.env.REACT_APP_HIDE_MERCHANT_CARD_ON_MENU !== undefined && process.env.REACT_APP_HIDE_MERCHANT_CARD_ON_MENU === 'true';
const displaySuburbName = process.env.REACT_APP_DISPLAY_SUBURB_NAME === 'true';
const hideFulfilmentType = process.env.REACT_APP_HIDE_FULFILMENT_TYPE !== undefined && process.env.REACT_APP_HIDE_FULFILMENT_TYPE === 'true';

// signin with apple
const isAppleSigninEnabled = process.env.REACT_APP_APPLE_SIGNIN_ENABLED === 'true';
const appleSigninClientId = isAppleSigninEnabled && process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID ? process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID : "";
const appleSigninRedirectUrl = process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URL ? process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URL : "https://app.mypreorder.com.au/services/s/apple.php";
// signin with google
const isGoogleSigninEnabled = process.env.REACT_APP_GOOGLE_SIGNIN_ENABLED === 'true';
const googleSigninClientId = isGoogleSigninEnabled && process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ? process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID : "";

let isAppleSigninAvailable = isAppleSigninEnabled && ((isCordova() && mobiscroll.platform.name === 'ios'/* && isAppleSigninLoaded()*/) || (!isCordova() && mobiscroll.platform.name !== 'android'  && appleSigninClientId !== "" && appleSigninRedirectUrl !== ""));
let isGoogleSigninAvailable = isGoogleSigninEnabled && googleSigninClientId !== ""; // && (!isCordova() || isGoogleSigninLoaded());
let anyThirdPartySigninAvailable = isAppleSigninAvailable || isGoogleSigninAvailable;

const emailSigninLabel = process.env.REACT_APP_CART_EMAIL_SIGNIN_LABEL ? process.env.REACT_APP_CART_EMAIL_SIGNIN_LABEL : (anyThirdPartySigninAvailable ? "Continue with email" : "Sign in or Create account");

class Cart extends Component {

    constructor(props) {
        super(props);
        //console.log('cart props', props);

        const fromLogin = props.hasOwnProperty('location') && props.location !== undefined && props.location.hasOwnProperty('state') && props.location.state !== undefined && props.location.state.hasOwnProperty('fromSetCustomerAction') ? props.location.state.fromSetCustomerAction : false;
        let cartExtra = props.hasOwnProperty('location') && props.location !== undefined && props.location.hasOwnProperty('state') && props.location.state !== undefined && props.location.state.hasOwnProperty('cartExtra') ? props.location.state.cartExtra : "";
        let showCart = cartExtra !== mpoFulfilment.fulfilmentTypeCodeDelivery;

        let deliveryAddressReqd = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let deliverySlotReqd = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let deliveryStopReqd = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let pickupLocationReqd = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions);
        if (deliveryStopReqd || (deliveryAddressReqd && (!this.isValidDeliveryAddress() || (deliverySlotReqd && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || cartExtra === mpoFulfilment.fulfilmentTypeCodeDelivery || fromLogin))) {
            showCart = false;
            cartExtra = mpoFulfilment.fulfilmentTypeCodeDelivery;
        } else if (pickupLocationReqd) {
            showCart = false;
            cartExtra = mpoFulfilment.fulfilmentTypeCodePickup;
        }

        this.state = {
            coupon_code: this.props.cart.order.coupon_code,
            note: this.props.cart.order.note,
            info: null,
            showCart: showCart,
            cartExtra: cartExtra
        }

        this.onPromoCodeChange = this.onPromoCodeChange.bind(this);
        this.onOrderNoteChange = this.onOrderNoteChange.bind(this);

        /*
        this.props.updateStateWithCart({
            order: {
                items: [
            ]}
        });
        */

        this.fulfilmentModal = React.createRef();

    }

    componentDidMount = () => {
        //console.log('Cart componentDidMount');
        mpoSentry.addBreadcrumb('nav','Cart','info');

        /* Apple login redirects to /login instead
        const params = queryString.parse(this.props.location.search);
        const method = params.method ? params.method : null;

        switch (method) {
            case "apple":
                //const code = params.code ? params.code : null;
                const id_token = params.id_token ? params.id_token : null;
                const user = params.user ? JSON.parse(params.user) : null;
                const state = params.state ? JSON.parse(params.state) : null;

                if (id_token) {
                    const decodedIdToken = decodeToken(id_token);
                    if (debugMode) logger(decodedIdToken);
                    const tokenIsExpired = isExpired(id_token);
                    if (tokenIsExpired) {
                        logger('Apple token has expired');
                        mobiscroll.toast({message: 'Apple Signin Token has expired', color: 'danger'});
                        this.loadCartPostDataHandler();
                    } else {
                        const data = {
                            user: decodedIdToken.sub, // apple user id
                            email:decodedIdToken.email,
                            fullName: {
                                familyName: user && user.lastName ? user.lastName : '',
                                givenName: user && user.firstName ? user.firstName : '',
                            }
                        };
                        const redirect = state && state.next ? state.next : null;
                        this.responseApple(data, redirect);
                        if (redirect === null || redirect === '/cart') {
                            this.loadCartPostDataHandler();
                        }
                    }
                }
                break;
            case "google":
                if (debugMode) {
                    logger('GOOGLE login');
                    logger(params);
                }
                this.loadCartPostDataHandler();
                break;
            default:
                if (debugMode) logger("Unknown or no login method param");
                this.loadCartPostDataHandler();
        }
         */
        this.loadCartPostDataHandler();

        /*
        // https://demo.mobiscroll.com/react/slider-progress/progress#theme=ios
        const labelList = ['Cart', 'Pay', 'Enjoy'];
        const checkoutLabels = document.querySelectorAll('.mpo-checkout-progress .mbsc-progress-step-label');
        // replace checkout progress labels
        for (let i = 0; i < checkoutLabels.length; ++i) {
            //console.log(labelList[i]);
            //console.log(checkoutLabels[i]);
            checkoutLabels[i].innerHTML = labelList[i];
            //console.log(checkoutLabels[i].innerHTML);
        }
        */
    }

    componentDidUpdate(prevProps) {
        //console.log('Cart componentDidUpdate');
        if (prevProps.fulfilmentType !== this.props.fulfilmentType && this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {
            //console.log(prevProps);
            //console.log(this.props);
            this.loadCartPostDataHandler();
        }
    }

    loadCartPostDataHandler = (showFulfilmentPopup = false) => {
        //logger('Cart loadCartPostDataHandler');
        //console.log(this.props);
        //console.log(unixDatetimeToDate(this.props.fulfilmentOptions.datetime));
        //console.log(unixDatetimeToTime(this.props.fulfilmentOptions.datetime));

        if (this.props.merchant.id && 
            this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0 &&
            this.props.fulfilmentOptions !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {

            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: 'Loading cart...', duration: 3000, display: 'center', color: 'info'});
            //logger(this.props.fulfilmentType);
            //logger(this.props.fulfilmentOptions);
            const checkout_options = {
                fulfilment_type: this.props.fulfilmentOptions.code,
                fulfilment_asap: this.props.fulfilmentOptions.asap ? 1 : 0,
                fulfilment_date: unixDatetimeToDate(this.props.fulfilmentOptions.datetime),
                fulfilment_time: unixDatetimeToTime(this.props.fulfilmentOptions.datetime)
            };

            if (this.props.user.checkout.delivery_address_id > 0) {
                checkout_options.delivery_address_id = this.props.user.checkout.delivery_address_id;
            } else if (this.props.user.checkout.delivery_address !== '') {
                const addr = this.props.user.checkout;
                checkout_options.delivery_address = addr.delivery_address1+','+addr.delivery_address2+','+addr.delivery_city+','+addr.delivery_state+','+addr.delivery_postcode+','+addr.delivery_country;
                checkout_options.delivery_address_str = addr.delivery_address;
            }

            const data = {
                RequestAction: 'ManageCart',
                sub_action: 'load_checkout',
                store_id: this.props.merchant.id,
                menu_id: this.props.merchant.menu_id,
                menu_sub_type: this.props.fulfilmentType.menu_sub_type, //legacy
                checkout_options: checkout_options,
                component: 'Cart'
            };

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    mobiscroll.notification.dismiss();
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        
                        this.props.updateStateWithCart(response.data.Response);
                        //this.props.updateStateWithOrder(response.data.Response.order);
                        if (response.data.Response.hasOwnProperty('merchant')) {
                            this.props.updateStateWithMerchant(response.data.Response.merchant);
                        }

                        if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                            this.props.updateStateWithCustomer({id: 0, status: 0});
                        }
                        
                        this.setState({
                            coupon_code: response.data.Response.order.coupon_code,
                            note: response.data.Response.order.note,
                            info: response.data.Response.menu.checkout_notice,
                        });
                        
                        if (showFulfilmentPopup) {
                            this.showFulfilmentPopup();
                        }

                        mpoFirebase.LogEvent('view_cart', {
                            currency: this.props.merchant.locale.currency_code,
                            //items: response.data.Response.order.items.length,
                            value: response.data.Response.order.total,
                        });

                    } else {
                        mobiscroll.toast({message: response.data.Response[0], duration: 10000, color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    }
                })
                .catch(error => {
                    mobiscroll.notification.dismiss();
                    //console.log(error);
                    mobiscroll.toast({message: 'Error CA1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });
        } else {
            this.props.updateStateWithCart({
                order: {
                    items: []
                }
            });
        }
    }

    showConfirmRemove= (data) => {
        mobiscroll.confirm({
            title: 'Remove product?',
            message: 'Are you sure you wish to remove this product from your cart?',
            okText: 'Yes',
            cancelText: 'No',
            callback: (res) => {
                if (res) {
                    this.updateQuantityPostDataHandler(data);
                } else {
                    // reset to 1 or reload cart
                    this.loadCartPostDataHandler();
                }
            }
        });
    }
    
    onQuantityChangeHandler = (e) => {
        //console.log('Cart onQuantityChangeHandler');
        //console.log(e);
        //console.log(e.target.valueAsNumber);
        const quantity = e.target.valueAsNumber;
        const cartItemSlotId = e.target.getAttribute("data-mpo-cart-item-slot-id");
        //console.log('slotId',cartItemSlotId);

        let cartItem = {
            quantity: quantity,
            cart_item_slot_id: cartItemSlotId
        }

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_quantity',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            menu_item_data: cartItem,
            component: 'Cart'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log(data);

        if (quantity === 0) {
            // confirm delete
            this.showConfirmRemove(data);
        } else {
            this.updateQuantityPostDataHandler(data);
        }
    }

    updateQuantityPostDataHandler = (data) => {
        //console.log('Cart updateQuantityPostDataHandler');
        mpoSentry.addBreadcrumb('cartUpdateQty','updateQuantityPostDataHandler','info');

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    // reload cart
                    this.loadCartPostDataHandler();

                    mpoFirebase.LogEvent('remove_from_cart', {
                        currency: this.props.merchant.locale.currency_code,
                        //items: response.data.Response.order.items.length,
                        //value: response.data.Response.order.total,
                    });
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }
            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error CA2, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });
    }

    clearCart = () => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'clear_cart',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: {},
            component: 'Cart'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    if (isAppModePersonal()) {
                        // reload cart
                        if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                            this.loadCartPostDataHandler();
                        }
                    } else {
                        // reset everything and start again
                        this.resetAllStates(null, null);
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }
            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error CA4, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
                this.loadCartPostDataHandler();
            });
    }

    updateCheckout = (checkout_options) => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: checkout_options,
            component: 'Cart'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {
                // reload cart
                if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                    this.loadCartPostDataHandler();
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], 'warning');
            }
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error CA3, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    onPromoCodeChange = (e) => {
        const updatedState = updateObject(this.state, {
            coupon_code: e.target.value.toUpperCase()
        });
        this.setState(updatedState);

    }

    updatePromoCode = (event, inst) => {
        if (event.button === 'set') {
            this.updateCheckout({
                coupon_code: this.state.coupon_code,
            })
        }
    }

    onOrderNoteChange = (e) => {
        const updatedState = updateObject(this.state, {
            note: e.target.value
        });
        this.setState(updatedState);
    }

    updateOrderNote = (event, inst) => {
        if (event.button === 'set') {
            this.updateCheckout({
                order_note: this.state.note,
            })
        }
    }

    showFulfilmentPopup = () => {

        mpoSentry.addBreadcrumb('nav','showFulfilmentPopup','info');

        let merchant = this.props.merchant;
        let fulfilment_types = this.props.merchant.fulfilment;

        if (fulfilment_types === undefined || fulfilment_types.length === 0 ||
            (merchant.hasOwnProperty('local_ts') && this.props.merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsFulfilment))) {
            logger('showFulfilmentPopup: reload from server');
            this.loadCartPostDataHandler(true);
        } else if (fulfilment_types !== undefined && fulfilment_types.length > 0) {
            //console.log(this.fulfilmentModal);
            //console.log('All', fulfilment_types);
            //console.log('Cur', this.props.fulfilmentType);
            //console.log('Opt', this.props.fulfilmentOptions);

            let selectedFulfilmentType = null;
            let selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(null, true);

            if (this.props.fulfilmentType === undefined || Object.keys(this.props.fulfilmentType).length === 0) {
                //this.props.updateStateWithFulfilmentType(fulfilment_types[0]);
                if (fulfilment_types.length === 1) {
                    selectedFulfilmentType = fulfilment_types[0];
                }
            } else {
                selectedFulfilmentType = this.props.fulfilmentType;
                // override in case of menu availability changes
                for (let ft in fulfilment_types) {
                    if (this.props.fulfilmentType.id === fulfilment_types[ft].id) {
                        selectedFulfilmentType = fulfilment_types[ft];
                        break;
                    }
                }
            }
            //logger(selectedFulfilmentType);

            if (selectedFulfilmentType !== null) {
                if (this.props.fulfilmentOptions === undefined || Object.keys(this.props.fulfilmentOptions).length === 0) {
                    let selectedFulfilmentTypeCode = mpoFulfilment.fulfilmentTypePickup;
                    for (let code in selectedFulfilmentType.fulfilment_types) {
                        selectedFulfilmentTypeCode = code;
                        break;
                    }

                    const asap = parseInt(selectedFulfilmentType.fulfilment_types[selectedFulfilmentTypeCode].asap, 10) === 1 &&
                        parseInt(selectedFulfilmentType.availability.available_today, 10) === 1;

                    selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(selectedFulfilmentTypeCode, asap);
                    //this.props.updateStateWithFulfilmentOptions(selectedFulfilmentOptions);
                } else {
                    selectedFulfilmentOptions = this.props.fulfilmentOptions;
                }
            }

            if (this.fulfilmentModal.current !== null) {
                this.fulfilmentModal.current.setState({
                    merchant_id: merchant.id,
                    fulfilment_types: fulfilment_types,
                    selected_fulfilment_type: selectedFulfilmentType === null ? {} : selectedFulfilmentType,
                    selected_fulfilment_options: selectedFulfilmentOptions
                });

                this.fulfilmentModal.current.refs.popupFulfilment.instance.show();
            } else {
                // component unmounted?
                mpoSentry.captureException("this.fulfilmentModal.current === null (cart)");
            }
        } else {
            mobiscroll.toast({message: "Fulfilment options not loaded, try again", color: 'danger'});
            mpoSentry.captureException("No fulfilment types (cart)");
        }

    }

    resetFulfilmentState = () => {
        this.props.updateStateWithFulfilmentType({});
        this.props.updateStateWithFulfilmentOptions({});
    }

    resetAllStates = (customer, orderId) => {
        this.props.updateStateWithCart({
            order: {
                items: []
            }
        });
        this.resetFulfilmentState();
        if (this.props.user.auth.isKiosk) {
            this.props.updateStateWithGuest({}, this.props, '/menu/'+this.props.cart.menu.menu_name);
            this.props.updateStateWithCheckout({});
        } else if (this.props.user.auth.isStaff) {
            if (!isDebugMode()) {
                this.props.updateStateWithGuest({});
            }
            this.props.updateStateWithCheckout({});
        } else {
            //this.props.updateStateWithCustomer(customer, this.props, '/account/orders/' + orderId);
        }
    }

    setShowCart = (showCart, cartExtra) => {
        const updatedState = updateObject(this.state, {
            showCart: showCart,
            cartExtra: cartExtra
        });
        this.setState(updatedState);
    }

    isValidDeliveryAddress = () => {
        return (this.props.user.checkout.delivery_address_id > 0 || this.props.user.checkout.delivery_postcode !== "") && this.props.user.checkout.delivery_validated && this.props.user.checkout.delivery_postcode_id > 0;
    }

    onFulfilmentPopupClose = () => {
        //logger('onFulfilmentPopupClose');
        //logger(this.state);
        if (!mpoFulfilment.validFulfilment(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            //logger('show again');
            mobiscroll.toast({message: "Fulfilment type is required", duration: 1500, color: 'danger'});
            setTimeout(() => {
                this.showFulfilmentPopup();
            }, 500);
        // do not prompt for delivery address at cart, wait until checkout
        // } else if (mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
        //     this.setShowCart(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) || mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowCart(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowCart(false, mpoFulfilment.fulfilmentTypeCodePickup);
        } else {
            // ensure cart is displayed, so screen is not left empty
            this.setShowCart(true, '');
        }
    }

    render = () => {
        //console.log('Cart render', this.props);

        const defaultLogoSquare = process.env.REACT_APP_DEFAULT_LOGO_SQUARE;
        const displayLogoSquare = process.env.REACT_APP_DISPLAY_LOGO_SQUARE === 'true';

        const isOrderPresent = this.props.cart !== undefined && this.props.cart.order !== undefined && this.props.cart.order.num_items && this.props.cart.order.num_items > 0;
        const isDelivery = isOrderPresent && this.props.cart.order.fulfilment_type === mpoFulfilment.fulfilmentTypeDelivery;
        const currencySign = isOrderPresent && this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                this.props.merchant.locale.hasOwnProperty('currency_sign') && this.props.merchant.locale.currency_sign ?
                                this.props.merchant.locale.currency_sign : '';

        const isDeliveryAddressRequired = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliverySlots = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions); //&& this.isValidDeliveryAddress();
        const showDeliveryStops = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showPickupLocations = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliveryOptions = isDeliveryAddressRequired || showDeliverySlots || showDeliveryStops || showPickupLocations;

        /*
        if (!isOrderPresent) {
            return (
                <div className="app-tab">
                    <div className="mbsc-empty">
                        <h3>Your cart is empty</h3>
                        {this.props.cart.menu ? 
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }}>Back to Menu</mobiscroll.Button>
                        </div> 
                        : null }
                    </div>

                    <AccountBottomNav />
                </div>
            );
        }
        */

        let cartItems = this.props.cart.order.items.map( item => (
            <CartProductItem
                key={item.cart_item_slot_id}
                item={item}
                onQuantityChangeHandler={this.onQuantityChangeHandler}
            />
        ) );

        const fulfilmentDesc = mpoFulfilment.getFulfilmentDesc(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const changeFulfilmentOptionDesc = "Change " + mpoFulfilment.getFulfilmentOptionDesc(this.props.fulfilmentType, this.props.fulfilmentOptions);

        const infoText = this.state.info ? <div className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(this.state.info)}}></div> : null;
        const promoText = this.props.merchant.hasOwnProperty('promo_text') && this.props.merchant.promo_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-bullhorn"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.promo_text)}}></span></mobiscroll.Note> : null;
        const paymentText = this.props.merchant.hasOwnProperty('payment_text') && this.props.merchant.payment_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-credit-card"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.payment_text)}}></span></mobiscroll.Note> : null;
        const deliveryText = this.props.merchant.hasOwnProperty('delivery_text') && this.props.merchant.delivery_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-motorcycle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.delivery_text)}}></span></mobiscroll.Note> : null;
        const warningText = this.props.merchant.hasOwnProperty('warning_text') && this.props.merchant.warning_text ? <mobiscroll.Note color="warning"><span className="empty icon fas fa-exclamation-triangle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.warning_text)}}></span></mobiscroll.Note> : null;

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${this.props.merchant.name} ${this.props.merchant.suburb} Cart`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md" labelStyle="stacked">
                    {this.props.merchant.id ? 
                    <React.Fragment>
                        <mobiscroll.Card>
                            { hideMerchantCardOnMenu ? null :
                            <mobiscroll.CardContent>
                                {displayLogoSquare ? <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em"}} src={this.props.merchant.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> : null }
                                <mobiscroll.CardTitle>{this.props.merchant.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.merchant.suburb : null}</mobiscroll.CardTitle>
                                <mobiscroll.CardSubtitle>
                                    <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.address1)}}></span> {!isCustomApp || isSingleStore || !displaySuburbName ? this.props.merchant.suburb : null}
                                    {this.props.merchant.phone ? <span style={{fontSize: "0.86em"}}><br/>Ph: {this.props.merchant.phone}</span> : null}
                                </mobiscroll.CardSubtitle>
                            </mobiscroll.CardContent>
                            }
                            {/*
                            <mobiscroll.CardFooter>
                                <label className="mpo-checkout-progress">
                                    <mobiscroll.Progress
                                        value={0}
                                        max={100}
                                        data-step-labels="[0, 50, 100]"
                                        color="success"
                                        data-icon='{ "left": "cart" }'
                                    />
                                </label>
                            </mobiscroll.CardFooter>
                            */}
                            {isOrderPresent && fulfilmentDesc !== "" && !hideFulfilmentType ?
                            <mobiscroll.CardFooter>
                                <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={() => {this.showFulfilmentPopup();}}>Change</mobiscroll.Button></span>
                                <span className="mbsc-txt-s">
                                    <span className="empty icon fas fa-clock"></span> {!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions) ? <span style={{color:"red"}}>{fulfilmentDesc}</span> : <span>{fulfilmentDesc}</span> }
                                </span>
                            </mobiscroll.CardFooter>
                            : null }
                            {infoText ?
                            <mobiscroll.CardFooter>
                                {infoText}
                            </mobiscroll.CardFooter>
                            : null }

                        </mobiscroll.Card>

                        {warningText}
                        {promoText}
                        {paymentText}
                        {deliveryText}

                    </React.Fragment>
                    : null }

                    {isOrderPresent ? 
                    <React.Fragment>
                        {this.state.showCart ?
                        <React.Fragment>
                            {cartItems}

                            {this.props.cart.order.total !== this.props.cart.order.grand_total ?
                            <div className="mbsc-padding" style={{paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Products total</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.total).toFixed(2)}</span>
                            </div>
                            : null }
                            {this.props.cart.order.sub_total - this.props.cart.order.total_after_rewards < 0 ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Rewards discount</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.sub_total - this.props.cart.order.total_after_rewards).toFixed(2)}</span>
                            </div>
                            : null }
                            {this.props.cart.order.total_after_discount < this.props.cart.order.total_after_rewards ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Other discount</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.total_after_rewards - this.props.cart.order.total_after_discount).toFixed(2)}</span>
                            </div>
                            : null }
                            {this.props.cart.order.surcharge_amount > 0 ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Surcharge</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.surcharge_amount).toFixed(2)}</span>
                            </div>
                            : null }
                            {isDelivery ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Delivery fee {mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !this.isValidDeliveryAddress() ? "(estimate*)" : ""}</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.delivery_fee).toFixed(2)}</span>
                            </div>
                            : null}
                            {this.props.cart.order.convenience_fee > 0 ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Preorder fee</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.convenience_fee).toFixed(2)}</span>
                            </div>
                            : null }
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Order total</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.grand_total).toFixed(2)}</span>
                            </div>
                            {isDelivery && mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !this.isValidDeliveryAddress() ?
                            <div className="mbsc-align-center mbsc-padding" style={{paddingTop: "0.2em", paddingBottom: 0}}>
                                <span className="mbsc-txt-s">*Enter delivery address at checkout to confirm delivery fee</span>
                            </div>
                            : null }

                            {parseInt(this.props.cart.menu.allow_promo_codes,10) === 1 || parseInt(this.props.cart.menu.allow_order_notes,10) === 1 ?
                            <div className="mbsc-btn-group" style={{textAlign: "center", margin: 0}}>
                                {parseInt(this.props.cart.menu.allow_promo_codes,10) === 1 ?
                                <span className="mbsc-txt-s">
                                    <mobiscroll.Button icon="bullhorn" flat={true} onClick={() => {this.refs.popupPromoCode.instance.show()}}>{this.state.coupon_code !== undefined && this.state.coupon_code !== null && this.state.coupon_code !== "" ? this.state.coupon_code : "Enter voucher or promo code"} </mobiscroll.Button>
                                </span>
                                : null }
                                {parseInt(this.props.cart.menu.allow_order_notes,10) === 1 ?
                                <span className="mbsc-txt-s">
                                    <mobiscroll.Button icon="pencil" flat={true} onClick={() => {this.refs.popupOrderNote.instance.show()}}>{this.state.note !== "" ? "Edit" : "Add"} instructions</mobiscroll.Button>
                                </span>
                                : null }
                            </div>
                            : null }
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button onClick={() => { this.props.user.auth.isLoggedIn && (this.props.user.auth.isMember || this.props.user.auth.isKiosk || this.props.user.auth.isStaff) ? this.props.history.push('/checkout') : this.refs.popupProceed.instance.show() }} color="success" style={{color: '#fff'}}>Proceed to Checkout</mobiscroll.Button>
                            </div> 

                            {isOrderPresent && fulfilmentDesc !== "" && !hideFulfilmentType && !mpoFulfilment.isCustomFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) ?
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="empty icon fas fa-clock" style={{textTransform: "capitalize"}} onClick={() => { this.showFulfilmentPopup(); }} disabled={this.state.submittingOrder}>{changeFulfilmentOptionDesc}</mobiscroll.Button>
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }}>Back to Menu</mobiscroll.Button>
                            </div> 
                            : 
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }}>Back to Menu</mobiscroll.Button>
                            </div> 
                            }
                            {isAppModePersonal() ? null : <div className="mbsc-btn-group-block">
                                <mobiscroll.Button color="danger" onClick={(e) => {
                                    e.preventDefault();
                                    mobiscroll.confirm({
                                        title: 'Cancel Order',
                                        message: 'Cancel this order and start again?',
                                        okText: 'Start Again',
                                        cancelText: 'Keep this Order'
                                    }).then((res) => {
                                        if (res) {
                                            this.clearCart();
                                        }
                                    });
                                }} disabled={this.state.submittingOrder}>Cancel Order</mobiscroll.Button>
                            </div> }
                        </React.Fragment>
                        :
                        <React.Fragment>
                        {showDeliveryOptions ?
                        <React.Fragment>
                            {isDeliveryAddressRequired ?
                            <DeliveryAddress headerText="Delivery" showTick={this.isValidDeliveryAddress()} bodyText={this.isValidDeliveryAddress() ? "We deliver to your address. |deliveryEstimate|" : "Enter your delivery address to check we deliver to your area"} showValidateButton={true} />
                            : null}

                            {showDeliverySlots ?
                            <DeliverySlot deliveryPostcodeId={this.props.user.checkout.delivery_postcode_id} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Slot" bodyText="Choose an available delivery date and time slot" />
                            : null }

                            {showDeliveryStops ?
                            <DeliveryStop reloadCart={this.loadCartPostDataHandler} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Location" bodyText="Choose an available delivery date and location" />
                            : null }

                            {showPickupLocations ?
                            <PickupLocation reloadCart={this.loadCartPostDataHandler} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Pickup Location" bodyText="Choose an available date and location" />
                            : null }

                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={(isDeliveryAddressRequired && !this.isValidDeliveryAddress()) || (showDeliverySlots && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showDeliveryStops && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showPickupLocations && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions))} onClick={() => { this.setShowCart(true, ''); }}>Proceed to Cart</mobiscroll.Button>
                            </div>
                        </React.Fragment>
                        : null }
                        </React.Fragment>
                        }
                    </React.Fragment> 
                    :
                    <React.Fragment>
                        <div className="mbsc-empty">
                            <h3>Your cart is empty</h3>
                            {this.props.cart.menu !== undefined && this.props.cart.menu.hasOwnProperty('menu_name') ? 
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }}>Back to Menu</mobiscroll.Button>
                            </div> 
                            : null }
                        </div>
                        {isAppModePersonal() ? <AccountBottomNav /> : null }
                    </React.Fragment>
                    }
    
                </mobiscroll.Form>

                <mobiscroll.Popup
                    ref="popupProceed"
                    display="center"
                    layout="liquid"
                    buttons={[]}
                >
                    <mobiscroll.Form>
                        <div>
                            {parseInt(this.props.cart.menu.require_registration,10) === 1 ?
                                <div className="mbsc-align-center"><h3 style={{marginTop: 0}}>An account is required to continue</h3></div>
                            :
                                <div className="mbsc-align-center"><h3 style={{marginTop: 0}}>Account Signin</h3></div>
                            }
                            {/*
                            <div className="mbsc-align-center" style={{marginBottom: "1em"}}>Sign in to use your saved details, repeat previous orders and earn rewards.</div>
                            */}
                            {anyThirdPartySigninAvailable ?
                                <React.Fragment>
                                    {isAppleSigninEnabled && isAppleSigninAvailable && mobiscroll.platform.name === 'ios' && isCordova() ?
                                        <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "0.5em auto"}}>
                                            <mobiscroll.Button icon="empty icon fas fa-apple"
                                                               block={true}
                                                               onClick={this.cordovaAppleLogin}
                                                               className="md-social-btn apple-login-btn">
                                                Continue with Apple
                                            </mobiscroll.Button>
                                        </div>
                                        : null}

                                    {isAppleSigninEnabled && isAppleSigninAvailable && mobiscroll.platform.name !== 'android' && !isCordova() ?
                                        <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "0.5em auto"}}>
                                            <AppleLogin clientId={appleSigninClientId}
                                                        redirectURI={appleSigninRedirectUrl}
                                                        designProp={{"width": 310, "height": 38, "type": "continue"}}
                                                        scope={"name email"}
                                                        responseType={"code id_token"}
                                                        responseMode={"form_post"}
                                                        state={encodeURIComponent(JSON.stringify({
                                                            return_url: window.location.protocol + '//' + window.location.hostname + '/#/login',
                                                            next: '/checkout',
                                                        }))}
                                            />
                                        </div>
                                        : null}

                                    {isGoogleSigninEnabled && isGoogleSigninAvailable && isCordova() ?
                                        <mobiscroll.FormGroup>
                                            <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "0.5em auto"}}>
                                                <mobiscroll.Button icon="empty icon fas fa-google"
                                                                   block={true}
                                                                   onClick={this.cordovaGoogleLogin}
                                                                   className="md-social-btn google-login-btn"
                                                                   style={{textTransform: "none"}}>
                                                    Continue with Google
                                                </mobiscroll.Button>
                                            </div>
                                        </mobiscroll.FormGroup>
                                        : null}

                                    {isGoogleSigninEnabled && isGoogleSigninAvailable && !isCordova() ?
                                        <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "0.5em auto"}}>
                                            <GoogleLogin
                                                //useOneTap={true}
                                                //auto_select
                                                text={"continue_with"}
                                                width={"310px"}
                                                logo_alignment={"center"}
                                                theme={"filled_black"}
                                                onSuccess={this.responseGoogleSuccess}
                                                onError={this.responseGoogleError}
                                            />
                                        </div>
                                        : null}

                                    <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "auto"}}>
                                        <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/checkout/login') }} color="primary" style={{textTransform: 'none'}}>{emailSigninLabel}</mobiscroll.Button>
                                    </div>
                                    {parseInt(this.props.cart.menu.require_registration,10) === 0 ?
                                        <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "auto"}}>
                                            <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/checkout') }} style={{textTransform: 'none'}}>Continue as Guest</mobiscroll.Button>
                                        </div>
                                        : null }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/checkout/login') }} color="primary" style={{textTransform: 'none'}}>{emailSigninLabel}</mobiscroll.Button>
                                    {parseInt(this.props.cart.menu.require_registration,10) === 0 ?
                                        <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/checkout') }} style={{textTransform: 'none'}}>Continue as Guest</mobiscroll.Button>
                                    : null }
                                </React.Fragment>
                            }
                        </div>
                    </mobiscroll.Form>
                </mobiscroll.Popup>

                <mobiscroll.Popup
                    ref="popupPromoCode"
                    headerText={mobiscroll.platform.name !== 'ios' ? "Voucher/Promo Code" : null}
                    responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                    layout="liquid"
                    buttons={[{text: 'Save', handler: 'set'}]}
                    onBeforeClose={this.updatePromoCode}
                >
                    <mobiscroll.Form>
                        <mobiscroll.Input inputStyle="box" labelStyle="stacked" icon="bullhorn" iconAlign="left" placeholder=""
                        type="text" name="promoCode" style={{textTransform: "uppercase"}}
                        value={this.state.coupon_code} onChange={this.onPromoCodeChange}>Enter voucher or promo code</mobiscroll.Input>
                        { isCordova() ?
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {mpoScanner.ScanQrCode(this.scanPromoCodeSuccess);}}>Scan QR Code</mobiscroll.Button>
                        </div>
                        : null }
                    </mobiscroll.Form>
                </mobiscroll.Popup>

                <mobiscroll.Popup
                    ref="popupOrderNote"
                    headerText={mobiscroll.platform.name !== 'ios' ? "Instructions" : null}
                    responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                    layout="liquid"
                    buttons={[{text: 'Save', handler: 'set'}]}
                    onBeforeClose={this.updateOrderNote}
                >
                    <mobiscroll.Form>
                        <mobiscroll.Textarea inputStyle="box" labelStyle="stacked" icon="pencil" iconAlign="left" placeholder="Special order instructions" value={this.state.note} onChange={this.onOrderNoteChange}>Instructions</mobiscroll.Textarea>
                    </mobiscroll.Form>
                </mobiscroll.Popup>

                <FulfilmentPopup ref={this.fulfilmentModal} onClose={this.onFulfilmentPopupClose} updateStateWithFulfilmentType={this.props.updateStateWithFulfilmentType} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} />

            </div>
        );
    }

    scanPromoCodeSuccess = (result) => {
        if (!result.cancelled && result.format === 'QR_CODE') {
            logger("QR code: " + result.text);
            const data = parseUrlSegments(result.text);
            if (data.promo_code) {
                const updatedState = updateObject(this.state, {
                    coupon_code: data.promo_code
                });
                this.setState(updatedState);
            }
        } else {
            mobiscroll.toast({message: 'Code not recognised', color: 'error'});
        }
    }

    responseApple = (response, redirect) => {
        if (response.hasOwnProperty('user') && response.user !== undefined && response.user !== "") {

            const data = {
                RequestAction: 'Login',
                method: 'apple',
                payload: response
            };

            mpoSentry.addBreadcrumb('action','cartLoginApple','info');

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        // set customer and redirect
                        mobiscroll.toast({message: "Signin successful", color: 'success'});
                        this.props.updateStateWithCustomer(response.data.Response, this.props, redirect);
                        Sentry.configureScope((scope) => {
                            scope.setUser({
                                "id": response.data.Response.id,
                                "email": response.data.Response.email
                            });
                        });
                        mpoSentry.addBreadcrumb('auth','Authenticated Apple user ' + response.data.Response.email,'info');
                    } else {
                        //logger(response);
                        mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    }

                })
                .catch(error => {
                    //logger(error);
                    mobiscroll.toast({message: 'Error LIC3, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });

        } else {
            logger(response);
            var msg = "Insufficient data from Apple to log you in. Access to your Name and Email address are required.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, 'error');
        }
    }

    cordovaAppleLogin = () => {
        if (isAppleSigninLoaded()) {
            /*eslint-disable no-undef*/
            window.cordova.plugins.SignInWithApple.signin(
                {requestedScopes: [0, 1]},
                response => {
                    //logger(response);
                    this.responseApple(response, '/checkout');
                },
                error => {
                    logger(error);
                    mobiscroll.toast({message: error.localizedDescription, color: 'danger'});
                    mpoSentry.captureMessage(error, 'warning');
                }
            )
            /*eslint-enable no-undef*/
        } else {
            var msg = "Sign in with Apple dependencies failed to load, please restart the app to try again.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, 'warning');
        }
    }

    cordovaGoogleLogin = () => {
        if (isGoogleSigninLoaded()) {
            /*eslint-disable no-undef*/
            if (debugMode) logger('Cart cordovaGoogleLogin called');
            window.plugins.googleplus.login(
                {
                    webClientId: googleSigninClientId
                },
                response => {
                    if (debugMode) logger(JSON.stringify(response));
                    this.responseGoogleSuccess(response);
                },
                error => {
                    logger(error);
                    if (error === "10") {
                        // if error === "10" then add keystore fingerprints to firebase app
                        mobiscroll.toast({message: "Error 10: Firebase SHA key missing or incorrect", color: 'danger'});
                        mpoSentry.captureWarnMessageAll(error);
                    } else {
                        mobiscroll.toast({message: error, color: 'danger'});
                        mpoSentry.captureMessage(error, 'warning');
                    }
                }
            )
            /*eslint-enable no-undef*/
        } else {
            var msg = "Sign in with Google unavailable (dependencies not loaded [C2])";
            //mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, 'warning');

            mobiscroll.snackbar({
                message: msg,
                color: 'danger',
                duration: 10000,
                button: {
                    text: 'Reload',
                    icon: 'empty icon fas fa-sync-alt',
                    action: (p) => {
                        window.location.reload();
                    }
                }
            });
        }
    }

    responseGoogleError = (response) => {
        if (debugMode) {
            logger('responseGoogleError called');
            logger(response);
        }
        if (response.hasOwnProperty('error') && response.error !== undefined && response.error !== "") {
            if (response.error === "10") {
                // if error === "10" then add keystore fingerprints to firebase app
                mpoSentry.captureWarnMessageAll(response.error);
                mobiscroll.toast({message: "Error 10: Firebase SHA key missing or incorrect", color: 'danger'});
            } else if (response.error === "idpiframe_initialization_failed") {
                mobiscroll.toast({message: "Google Login unavailable (initialisation failed).", color: 'danger'});
                isGoogleSigninAvailable = false;
            } else if (response.hasOwnProperty('details') && response.details !== undefined && response.details !== "") {
                mobiscroll.toast({message: "Google Login error: "+response.details, color: 'danger'});
            } else {
                mobiscroll.toast({message: "Google Login error: "+response.error, color: 'danger'});
            }
        } else {
            mobiscroll.toast({message: "Error logging in with Google.", color: 'danger'});
        }
    }

    responseGoogleSuccess = (response) => {
        if (debugMode) {
            logger('responseGoogleSuccess called');
            logger(response);
        }
        let payload = {};
        if (isCordova() && response.hasOwnProperty('email') && response.email !== undefined && response.email !== "") {
            // response from cordova plugin needs to be molded to meet our server expectations, which are based on new google oauth2 jwt...
            payload = response;
            payload.sub = response.userId;
            payload.name = response.displayName;
            payload.given_name = response.givenName;
            payload.family_name = response.familyName;
            payload.picture = response.imageUrl;
            payload.identityToken = response.idToken;
            payload.authorizationCode = response.serverAuthCode;
        }
        else if (response.hasOwnProperty('credential') && response.credential !== undefined && response.credential !== "") {
            // google oauth2 jwt
            const decodedIdToken = decodeToken(response.credential);
            payload = decodedIdToken;
            payload.identityToken = response.credential;
        }
        if (debugMode) logger(payload);

        if (payload.hasOwnProperty('email') && payload.email !== undefined && payload.email !== "") {
            const data = {
                RequestAction: 'Login',
                method: 'google',
                payload: payload
            };

            mpoSentry.addBreadcrumb('action','cartLoginGoogle','info');

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        // set customer and redirect
                        mobiscroll.toast({message: "Signin successful", color: 'success'});
                        this.props.updateStateWithCustomer(response.data.Response, this.props, '/checkout');
                        Sentry.configureScope((scope) => {
                            scope.setUser({
                                "id": response.data.Response.id,
                                "email": response.data.Response.email
                            });
                        });
                        mpoSentry.addBreadcrumb('auth','Authenticated Google user ' + response.data.Response.email,'info');
                    } else {
                        //logger(response);
                        mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    }

                })
                .catch(error => {
                    //logger(error);
                    mobiscroll.toast({message: 'Error LIC4, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });

        } else {
            logger(response);
            var msg = "Unknown response from Google.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, 'error');
        }
    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithMerchant: (merchant) => dispatch(actions.setMerchantAction(merchant)),
        updateStateWithFulfilmentType: (fulfilmentType) => dispatch(actions.setFulfilmentTypeAction(fulfilmentType)),
        updateStateWithFulfilmentOptions: (fulfilmentOptions) => dispatch(actions.setFulfilmentOptionsAction(fulfilmentOptions)),
        updateStateWithCart: (cart) => {
            dispatch(actions.setCartAction(cart));
            dispatch(actions.setOrderAction(cart.order));
        },
        updateStateWithCustomer: (customer, ownProps, redirect) => {
            redirect = redirect || null;
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
        updateStateWithGuest: (guest, ownProps, redirect) => dispatch(actions.setGuestAction(guest, ownProps, redirect)),
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout))
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( Cart, axiosInstance ));
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(Cart));

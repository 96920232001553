
export const defaultInternationalDiallingCode = (countryCode) => {
    switch (countryCode.toUpperCase()) {
        case "AU":
            return "+61";
        case "NZ":
            return "+64";
        case "US":
            return "+1";
        case "GB":
            return "+44";
        default:
            return "+";
    }
};
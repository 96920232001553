import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";

//const isResellerApp = process.env.REACT_APP_RESELLER_APP !== undefined && process.env.REACT_APP_RESELLER_APP === 'true';
const hideHome = process.env.REACT_APP_HIDE_HOME !== undefined && process.env.REACT_APP_HIDE_HOME === 'true';
//const hideAbout = process.env.REACT_APP_HIDE_ABOUT !== undefined && process.env.REACT_APP_HIDE_ABOUT === 'true';
const hideAccount = process.env.REACT_APP_HIDE_ACCOUNT !== undefined && process.env.REACT_APP_HIDE_ACCOUNT === 'true';
const hideSecurity = process.env.REACT_APP_HIDE_SECURITY !== undefined && process.env.REACT_APP_HIDE_SECURITY === 'true';
const hideLogout = process.env.REACT_APP_HIDE_LOGOUT !== undefined && process.env.REACT_APP_HIDE_LOGOUT === 'true';
const internationalSupport = process.env.REACT_APP_INTERNATIONAL_SUPPORT !== undefined && process.env.REACT_APP_INTERNATIONAL_SUPPORT === 'true';

export default class AccountBottomNav extends Component {

    render = () => {
        //console.log(this.props);
        const showHome = !hideHome && !(this.props.hasOwnProperty('showRewards') && this.props.showRewards) && !(this.props.hasOwnProperty('showTopup') && this.props.showTopup);

        return (
            <mobiscroll.BottomNav>
                {showHome ? <mobiscroll.NavItem to="/" exact={true} icon="empty icon fas fa-home">Home</mobiscroll.NavItem> : null }
                {!hideAccount ? <mobiscroll.NavItem to="/account" exact={true} icon="empty icon fas fa-address-card">Account</mobiscroll.NavItem> : null }
                <mobiscroll.NavItem to="/account/orders" icon="empty icon fas fa-receipt">Orders</mobiscroll.NavItem>
                { this.props.hasOwnProperty('showRewards') && this.props.showRewards ? <mobiscroll.NavItem to="/account/rewards" icon="empty icon fas fa-certificate">Rewards</mobiscroll.NavItem> : null }
                <mobiscroll.NavItem to="/account/managecard" icon="empty icon fas fa-credit-card">Billing</mobiscroll.NavItem>
                {/*
                { this.props.hasOwnProperty('showTopup') && this.props.showTopup ? <mobiscroll.NavItem to="/account/topup" icon="empty icon fas fa-credit-card">Topup</mobiscroll.NavItem> : null }
                { this.props.hasOwnProperty('showTopup') && this.props.showTopup ? <mobiscroll.NavItem to="/account/withdraw" icon="empty icon fas fa-credit-card">Withdraw</mobiscroll.NavItem> : null }
                <mobiscroll.NavItem to="/account/deliveries" icon="empty icon fas fa-truck">Delivery Addresses</mobiscroll.NavItem>
                <mobiscroll.NavItem to="/account/invoices" icon="empty icon fas fa-file-alt" badge="0">Unpaid Invoices</mobiscroll.NavItem>
                <mobiscroll.NavItem to="/account/notifications" icon="empty icon fas fa-bell" badge="0">Notifications</mobiscroll.NavItem>
                <mobiscroll.NavItem to="/account/settings" icon="empty icon fas fa-cog">Settings</mobiscroll.NavItem>
                */}
                <mobiscroll.NavItem to="/account/feedback" icon="empty icon fas fa-comment">Feedback</mobiscroll.NavItem>
                { hideSecurity || (this.props.hasOwnProperty('thirdPartyConnected') && this.props.thirdPartyConnected) ? null : <mobiscroll.NavItem to="/account/password" icon="empty icon fas fa-user-lock">Change Password</mobiscroll.NavItem> }
                { internationalSupport || hideSecurity || (this.props.hasOwnProperty('thirdPartyConnected') && this.props.thirdPartyConnected) ? null : <mobiscroll.NavItem to="/account/pin" icon="empty icon fas fa-user-lock">Change PIN</mobiscroll.NavItem> }
                { !hideLogout ? <mobiscroll.NavItem to="/logout" icon="empty icon fas fa-sign-out-alt">Logout</mobiscroll.NavItem> : null }
            </mobiscroll.BottomNav>
        );
    }
}
